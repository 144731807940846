var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vx-card",
        {
          staticClass:
            "w-auto shadow-drop border-solid border-grey border playful",
          attrs: { headerStyle: "w-full text-left font-bold text-white pb-4" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "pt-2 flex items-center justify-between" },
                    [
                      _c("label", { staticClass: "_class-card-title" }, [
                        _vm._v(_vm._s(_vm._.get(_vm.item, "name"))),
                      ]),
                      false
                        ? _c("feather-icon", {
                            staticClass: "cursor-pointer",
                            attrs: {
                              svgClasses: "w-5 h-5 2xl:w-4 2xl:h-4",
                              icon: "MoreVerticalIcon",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "w-full flex flex-shrink-0 flex-wrap" },
                    [
                      _vm.corporate_sale
                        ? _c(
                            "div",
                            { staticClass: "w-full grid grid-cols-2 gap-2" },
                            [
                              _c(
                                "div",
                                { staticClass: "flex" },
                                [
                                  _c("vs-input", {
                                    attrs: {
                                      lang: "pt-BR",
                                      type: "number",
                                      label: _vm.$t("marketing_place.quantity"),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.quantityChange($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.quantity,
                                      callback: function ($$v) {
                                        _vm.quantity = $$v
                                      },
                                      expression: "quantity",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "flex" },
                                [
                                  _c("vs-input", {
                                    attrs: {
                                      readonly: "",
                                      label: _vm.$t("marketing_place.total"),
                                    },
                                    model: {
                                      value: _vm.priceTotal,
                                      callback: function ($$v) {
                                        _vm.priceTotal = $$v
                                      },
                                      expression: "priceTotal",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-full flex gap-2 items-center justify-center pt-4",
                        },
                        [
                          _c(
                            "vs-button",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: {
                                    content: _vm.$t(
                                      "marketing_place.add_to_cart"
                                    ),
                                    delay: { show: 500, hide: 100 },
                                  },
                                  expression:
                                    "{\n                  content: $t('marketing_place.add_to_cart'),\n                  delay: { show: 500, hide: 100 }\n                }",
                                },
                              ],
                              staticClass: "px-2 py-2",
                              attrs: { icon: "add" },
                              on: { click: _vm.addItem },
                            },
                            [
                              _c("span", { staticClass: "inline pl-1" }, [
                                _vm._v(_vm._s(_vm.$t("adicionar"))),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            { staticClass: "flex flex-col w-full gap-3 justify-center" },
            [
              _c("p", {
                staticClass: "_class-card-description",
                domProps: {
                  innerHTML: _vm._s(_vm._.get(_vm.item, "description")),
                },
              }),
              _c(
                "div",
                { staticClass: "flex justify-center" },
                [
                  _c(
                    "vs-button",
                    {
                      attrs: { type: "border" },
                      on: {
                        click: function ($event) {
                          return _vm.openDetailItem(_vm.item)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("action.detail")))]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "flex justify-center" }, [
                _c("label", { staticClass: "text-md" }, [
                  _c("span", { staticClass: "font-bold" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.formatValue(_vm._.get(_vm.item, "price")))
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _c("ProductDetailPopup", {
        key: _vm.keyPopup,
        attrs: { item: _vm.item, visible: _vm.showProductDetailPopup },
        on: { close: _vm.closeProductDetailPopup },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }