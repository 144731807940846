var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vs-dropdown",
    {
      staticClass: "cursor-pointer",
      attrs: { "vs-custom-content": "", "vs-trigger-click": "" },
    },
    [
      _c("feather-icon", {
        staticClass: "cursor-pointer ml-4 mr-6 mt-1",
        attrs: { icon: "ShoppingCartIcon", badge: _vm.cartItemsLength },
      }),
      _c(
        "vs-dropdown-menu",
        {
          staticClass: "cart-dropdown vx-navbar-dropdown",
          class: { "dropdown-custom": _vm.cartItems.length },
        },
        [
          _vm.cartItems.length
            ? [
                _c(
                  "div",
                  {
                    staticClass:
                      "notification-header text-center p-5 bg-primary text-white",
                  },
                  [
                    _c("h3", { staticClass: "text-white" }, [
                      _vm._v(" " + _vm._s(_vm.cartItemsLength) + " "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.cartItemsLength > 1,
                              expression: "cartItemsLength > 1",
                            },
                          ],
                        },
                        [_vm._v(_vm._s(_vm.$t("marketing_place.items")))]
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.cartItemsLength <= 1,
                              expression: "cartItemsLength <= 1",
                            },
                          ],
                        },
                        [_vm._v(_vm._s(_vm.$t("marketing_place.item")))]
                      ),
                    ]),
                    _c("p", { staticClass: "opacity-75" }, [
                      _vm._v(_vm._s(_vm.$t("marketing_place.items_in_cart"))),
                    ]),
                  ]
                ),
                _c(
                  _vm.scrollbarTag,
                  {
                    key: _vm.$vs.rtl,
                    ref: "mainSidebarPs",
                    tag: "component",
                    staticClass: "scroll-area--cart-items-dropdowm p-0 mb-10",
                    attrs: { settings: _vm.settings },
                  },
                  [
                    _c(
                      "ul",
                      { staticClass: "bordered-items" },
                      _vm._l(_vm.cartItems, function (item) {
                        return _c(
                          "li",
                          {
                            key: item.id,
                            staticClass:
                              "vx-row no-gutter cart-item cursor-pointer",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vx-col w-4/5 pr-4 pl-2 py-4 flex flex-col justify-center",
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "font-medium block cart-item-title truncate pb-4",
                                  },
                                  [_vm._v(_vm._s(item.product_name))]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-between",
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "text-sm font-small" },
                                      [
                                        _vm._v(_vm._s(item.quantity) + " "),
                                        _c("small", [_vm._v("x")]),
                                        _vm._v(
                                          _vm._s(_vm.formatValue(item.price))
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vx-col w-1/5 pr-4 pl-2 py-4 flex flex-col justify-start items-center",
                              },
                              [
                                _c("feather-icon", {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: {
                                        content: _vm.$t(
                                          "marketing_place.remove_from_cart"
                                        ),
                                        delay: { show: 500, hide: 100 },
                                      },
                                      expression:
                                        "{\n                                      content: $t('marketing_place.remove_from_cart'),\n                                      delay: { show: 500, hide: 100 }\n                                    }",
                                    },
                                  ],
                                  staticClass: "hover:text-danger",
                                  attrs: {
                                    icon: "TrashIcon",
                                    svgClasses:
                                      "h-4 w-4 cursor-pointer text-danger",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.removeItemFromCart(item)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "checkout-footer static bottom-0 rounded-b-lg text-primary font-semibold w-full p-2 text-center border border-b-0 border-l-0 border-r-0 border-solid d-theme-border-grey-light cursor-pointer",
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "flex items-center justify-center pt-2 pb-4",
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.formatValue(_vm.valueTotal)) + " "
                        ),
                      ]
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "flex items-center justify-center",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.paymentPurchase()
                          },
                        },
                      },
                      [
                        _c("feather-icon", {
                          attrs: {
                            icon: "ShoppingCartIcon",
                            svgClasses: "h-4 w-4",
                          },
                        }),
                        _c("span", { staticClass: "ml-2" }, [
                          _vm._v(_vm._s(_vm.$t("action.payment"))),
                        ]),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            : [
                _c("p", { staticClass: "p-4" }, [
                  _vm._v(_vm._s(_vm.$t("marketing_place.cart_is_empty"))),
                ]),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }