var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("infinite-grid", {
    ref: "grid",
    staticClass: "infinite-grid",
    style: _vm.infiniteGridStyleInjection,
    attrs: {
      "order-column": "name",
      direction: "asc",
      method: this.corporate_sale
        ? `getProductToSalesCorporateGrid`
        : `getProductToSalesGrid`,
      extraParams: _vm.extraParam,
      service: _vm.service,
    },
    scopedSlots: _vm._u([
      {
        key: "item",
        fn: function (data) {
          return [
            _c("product-card", {
              attrs: {
                item: data.item,
                corporate_sale: _vm.corporate_sale,
                validatedExistProductInCart: _vm.existItemCart,
              },
              on: { updateCartHandling: _vm.updateCartHandling },
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }